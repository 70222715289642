import { apiReports, baseUrlReports, apiToken } from "@/store/api";


const leadReports = {
  namespaced: true,

  state: () => ({
    status: 0,
    totalLeads: 0,
    totalSolicitacoes: 0,
    totalSolicitacoesToday: 0,
    totalSolicitacoesYesterday: 0,
    totalSolicitacoesLast5Days: 0,
    totalLeadsRegistered: 0,
    totalSolicitacoesRegistered: 0,
    dataSolicitacoes: [],
    statusDataSolic: 0,
    statusExcelReportBasic: 0,
    hiddenProgress: true,
    reportURL: '#',
    showType: 0,
    showFilter: false,
    dayFilterClicked: 0,
    searchActive: false,
    headerCheckedAltered: false,
    headersChecked: [],
    searchTotalPages: 0,
    searchSolicitacaoWords: '',
    searchSolicitacao: {
      startDate: '',
      endDate: '',
      page: 1,
      itemsPerPage: 200,
      pageCount: 1,
      totalRequests: 0,
      words: ''
    },
    searchCustomDate: false,
    searchFormFilter: {
      // a ordem desses campos
      // deve seguir a mesma sequência
      // que for ser processada na api
      // se um campo for trocado de posição
      // isso deve ser repletido na api,
      // e vice versa.
      nome: "",
      email: "",
      celular: "",
      cpf: "",
      cnpj: "",
      cidade: "",
      uf: "",
    },
    searchFilterFieldLastFocus: '',
    searchPage: 1,
    filterReport: {
      startDate: '',
      endDate: '',
    },
    filterSelected: {
      tipo_emprestimo: null,
      cidade: null,
      uf: null,
      status: null,
      status_op: null,
      match_parceiro: null,
      ocupacao: null,
      restricao_spc: null,
      nome_afiliado: null
    },
    filtrosAgrupados: {},
    statusFiltrosAgrupados: null,
    creditasStatus: {},
    showModalExportSolics: false,
    uploadFileStatus: 0,
    ultimosStatusOp: []
  }),

  getters: {
    getData: state => {
      return state.dataLeads && Array.isArray(state.dataLeads.leads) ? state.dataLeads.leads.map(e => {
        e.idade = e.dataNasc ? new Date().getFullYear() - e.dataNasc.split("/").reverse()[0] : ''
        return e;
      }) : []
    }
  },

  mutations: {
    setTotalSolicitacoes: (state, payload) => state.totalSolicitacoes = payload,
    setTotalSolicitacoesToday: (state, payload) => state.totalSolicitacoesToday = payload,
    setTotalSolicitacoesYesterday: (state, payload) => state.totalSolicitacoesYesterday = payload,
    setTotalSolicitacoesLast5Days: (state, payload) => state.totalSolicitacoesLast5Days = payload,
    setTotalLeads: (state, payload) => state.totalLeads = payload,
    setTotalLeadsRegistered: (state, payload) => state.totalLeadsRegistered = payload,
    setTotalSolicitacoesRegistered: (state, payload) => state.totalSolicitacoesRegistered = payload,
    setDataSolicitacoes: (state, payload) => state.dataSolicitacoes = payload,
    setStatus: (state, payload) => state.status = payload,
    setStatusDataSolic: (state, payload) => state.statusDataSolic = payload,
    setStatusExcelReportBasic: (state, payload) => state.statusExcelReportBasic = payload,
    setReportURL: (state, payload) => state.reportURL = payload,
    setShowType: (state, payload) => state.showType = payload,
    setShowFilter: (state, payload) => state.showFilter = payload,
    setSearchSolicitacao: (state, payload) => state.searchSolicitacao = payload,
    setSearchSolicitacaoPage: (state, payload) => state.searchPage = payload,
    setSearchSolicitacaoItems: (state, payload) => state.searchSolicitacao.itemsPerPage = payload,
    setSearchSolicitacaoCount: (state, payload) => state.searchSolicitacao.pageCount = payload,
    setSearchSolicitacaoTotal: (state, payload) => state.searchSolicitacao.totalRequests = payload,
    setSearchSolicitacaoWords: (state, payload) => state.searchSolicitacaoWords = payload,
    setSearchFormFilter: (state, payload) => state.searchFormFilter = payload,
    setSearchFilterFieldLastFocus: (state, payload) => state.searchFilterFieldLastFocus = payload,
    setSearchCustomDate: (state, payload) => state.searchCustomDate = payload,
    setSearchTotalPages: (state, payload) => state.searchTotalPages = Math.abs(Math.ceil(Number(payload) / 200)),
    setSearchActive: (state, payload) => state.searchActive = payload,
    setHeaderCheckedAltered: (state, payload) => state.headerCheckedAltered = payload,
    setHeadersChecked: (state, payload) => state.headersChecked = payload,
    setDayFilterClicked: (state, payload) => state.dayFilterClicked = payload,
    setHiddenProgress: (state, payload) => state.hiddenProgress = payload,
    setFilterReport: (state, payload) => state.filterReport = payload,
    setFiltrosAgrupados: (state, payload) => state.filtrosAgrupados[payload.filtername] = payload.data,
    setStatusFiltrosAgrupados: (state, payload) => state.statusFiltrosAgrupados = payload,
    setFilterSelected: (state, payload) => state.filterSelected = payload,
    setCreditasStatus: (state, payload) => state.creditasStatus = payload,
    setShowModalExportSolics: (state, payload) => state.showModalExportSolics = payload,
    setUploadFileStatus: (state, payload) => state.uploadFileStatus = payload,
    setUltimoStatusOp: (state, payload) => state.ultimosStatusOp = payload
  },

  actions: {
    getTotals({ commit }, { type }) {
      return new Promise((resolve, reject) => {
        commit('setStatus', 1)
        apiReports.get(`api/totals/${type}`)
          .then(res => {
            commit('setStatus', 0)

            switch (type) {
              case 'solicToday': commit('setTotalSolicitacoesToday', res.data.content.total); break;
              case 'solicYesterday': commit('setTotalSolicitacoesYesterday', res.data.content.total); break;
              case 'solicLast5Days': commit('setTotalSolicitacoesLast5Days', res.data.content.total); break;
              case 'solic': commit('setTotalSolicitacoesRegistered', res.data.content.total); break;
              case 'leads': commit('setTotalLeadsRegistered', res.data.content.total)
            }

            resolve(res.data.content.total)

          })
          .catch(err => {
            commit('setStatus', -1)
            reject(err)
            console.error(err)
          })
      })
    },
    getSolicitacoes: ({ commit }, { page }) => new Promise((resolve, reject) => {
      commit('setStatusDataSolic', 1)
      apiReports.get(`/api/requests?page=${page}`)
        .then(res => {
          commit('setStatusDataSolic', 0)
          //commit('setDataSolicitacoes', res.data.content)          
          resolve(res.data.content)

        })
        .catch(err => {
          commit('setStatusDataSolic', -1)
          console.error(err)
          reject(err.response)
        })
    }),


    getSearchSolicitacao: ({ commit, state }, { page, words, filterwords }) => new Promise((resolve, reject) => {
      commit('setStatusDataSolic', 1)
      //const { searchSolicitacao } = state;

      const data = {
        'startDate': state.searchSolicitacao.startDate,
        'endDate': state.searchSolicitacao.endDate,
      }

      apiReports.post(`/api/mongodb/dashboard?page=${page}&words=${words}&filterwords=${filterwords}`, data)
        .then(res => {
          commit('setSearchActive', true)
          commit('setStatusDataSolic', 0)
          commit('setTotalSolicitacoes', res.data.content.requests.length)
          resolve(res.data)
        })
        .catch(err => {
          commit('setStatusDataSolic', -1)
          console.error(err)
          reject(err.response)
        })
    }),


    getFiltrosAgrupados: ({ commit, state }, { filtros }) => new Promise((resolve, reject) => {
      const data = {
        'startDate': state.searchSolicitacao.startDate,
        'endDate': state.searchSolicitacao.endDate,
      }

      // filtros
      //   cidade
      //   uf
      //   tipo_emprestimo
      //   restricao_spc
      //   ocupacao
      //   status_op
      //   status
      //   match_parceiro
      //   nome_afiliado

      if (state.setStatusFiltrosAgrupados) return;

      const api = (filtername) => {

        if (state.setStatusFiltrosAgrupados) return;

        commit('setStatusFiltrosAgrupados', filtername)
        apiReports.post(`/api/mongodb/filters?filtername=${filtername}`, data)
          .then(res => {
            let data = res.data.content.filter.map(e => ({ [filtername]: e._id, qtd: e.qtd })).filter(e => e[filtername]);
            data = dataReducer(filtername, data);
            commit('setFiltrosAgrupados', { filtername, data })
            const index = filtros.indexOf(filtername)
            if (index < (filtros.length - 1)) {
              api(filtros[index + 1])
            } else {
              return commit('setStatusFiltrosAgrupados', null)
            }


            function dataReducer(type, data) {
              if (type === 'status_op') {
                const fnNFD = str => str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '_')
                return data.reduce((grp, it) => {
                  const added = grp.find(e => fnNFD(e.status_op) === fnNFD(it.status_op));
                  if (added) {
                    added.qtd += it.qtd;
                  } else {
                    it.status_op = it.status_op.toUpperCase().replace(/_/g, ' ');
                    grp.push(it);
                  }
                  return grp;
                }, [])
              }
              return data;
            }
          })
          .catch(err => {
            console.error(err)
            reject(err.response)
          })
      }

      api(filtros[0])

    }),

    getSolicitacoesFiltradas: ({ commit, state }, { words, filterwords, page }) => new Promise((resolve, reject) => {
      commit('setStatusDataSolic', 1)

      const data = {
        'startDate': state.searchSolicitacao.startDate,
        'endDate': state.searchSolicitacao.endDate,
      }


      apiReports.post(`/api/mongodb/dashboard?page=${page}&words=${words}&filterwords=${filterwords}`, data)
        .then(res => {
          commit('setSearchActive', true)
          commit('setStatusDataSolic', 0)
          commit('setTotalSolicitacoes', res.data.content.requests.length)
          commit('setSearchSolicitacaoTotal', res.data.totalReq.total)
          resolve(res.data)
        })
        .catch(err => {
          commit('setStatusDataSolic', -1)
          console.error(err)
          reject(err.response)
        })

    }),

    getAllStatusOp: ({ commit }, { solicId }) => new Promise((resolve, reject) => {

      apiReports.get(`/api/all-status-op?solicId=${solicId}`)
        .then(res => {
          commit('setUltimoStatusOp', res.data.content)
          resolve(res.data.content)
        })
        .catch(err => {
          console.error(err);
          reject(err.response);
        })
    }),

    getCreditasStatus: ({ commit }, { solicitacaoId }) => new Promise((resolve, reject) => {
      apiReports.get(`/api/creditas/status/${solicitacaoId}`)
        .then(res => {
          commit('setCreditasStatus', res.data)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    }),

    getExcelReportBasic: ({ commit, state }) => new Promise((resolve, reject) => {
      commit('setStatusExcelReportBasic', 1)
      //const { searchSolicitacao } = state;

      const data = {
        'startDate': state.searchSolicitacao.startDate,
        'endDate': state.searchSolicitacao.endDate,
      }

      apiReports.post(`/api/excel/basic`, data)
        .then(res => {
          commit('setStatusExcelReportBasic', 0)
          commit('setReportURL', res.data.content.url)
          resolve(res.data.content.url)
        })
        .catch(err => {
          commit('setStatusExcelReportBasic', -1)
          console.error(err)
          reject(err.response)
        })
    }),

    getReportSolicitacoesComVeiculo: ({ state }, { solicId }) => new Promise((resolve, reject) => {
      const data = {
        'startDate': state.searchSolicitacao.startDate,
        'endDate': state.searchSolicitacao.endDate,
      }

      const params = solicId ? `?solicId=${solicId}` : ''

      apiReports.post(`/api/export-solicitacoes-com-veiculo${params}`, data)
        .then(res => {
          resolve(res.data.content)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    }),

    exportMultiSolicitacoesComVeiculo: (ctx, { solicIds, options }) => new Promise((resolve, reject) => {

      apiReports.post(`/api/export-solicitacoes-com-veiculo-selecionados`, { solicIds, options })
        .then(res => {
          resolve(res.data.content)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    }),

    getReportSolicitacoesComImovel: ({ state }, { solicId }) => new Promise((resolve, reject) => {
      const data = {
        'startDate': state.searchSolicitacao.startDate,
        'endDate': state.searchSolicitacao.endDate,
      }

      const params = solicId ? `?solicId=${solicId}` : ''

      apiReports.post(`/api/export-solicitacoes-com-imovel${params}`, data)
        .then(res => {
          resolve(res.data.content)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    }),

    exportMultiSolicitacoesComImovel: (ctx, { solicIds, options }) => new Promise((resolve, reject) => {

      apiReports.post(`/api/export-solicitacoes-com-imovel-selecionados`, { solicIds, options })
        .then(res => {
          resolve(res.data.content)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    }),

    getFilesAffiliateBySolic: (ctx, { solicId }) => new Promise((resolve, reject) => {
      apiReports.get(`/api/affiliate/show-files?solic=${solicId}`)
        .then(res => {
          resolve(res.data.content)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    }),

    downloadFileAffiliateBySolic: (ctx, { id }) => new Promise((resolve, reject) => {
      apiReports.get(`/api/affiliate/download-file?id=${id}`, { responseType: 'blob' })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    }),

    uploadFileAffiliateBySolic: ({commit}, { id, uploadFn }) => new Promise((resolve, reject) => {

      const uploadUrl = `${baseUrlReports}/api/affiliate/upload-file?id=${id}`;
      const uploadHeaders = apiToken;

      commit('setUploadFileStatus', 1)

      uploadFn({uploadUrl, uploadHeaders})
        .then(res => {
          resolve(res[0].data.content)
          commit('setUploadFileStatus', 0)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
          commit('setUploadFileStatus', -1)
        })
    })
  }
}

export default leadReports
