import leadReports from "@/store/dashboard/lead-reports";


const dashboard = {
  namespaced: true,
  modules: {
    leadReports
  }
}

export default dashboard
