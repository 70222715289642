<template>
  <div class="layout-vertical">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "vertical"
}
</script>

<style scoped>

</style>
