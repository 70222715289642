import { apiCore } from '../api'

const solicStatus = {
  namespaced: true,
  state: () => ({
    data: [],
    status: 0,
    error: ''
  }),
  mutations: {
    setData: (state, payload) => state.data = payload,
    setStatus: (state, payload) => state.status = payload,
    setError: (state, payload) => state.error = payload
  },
  actions: {
    postSolicitacaoStatusManual: (ctx, data) => new Promise((resolve, reject) => {
      apiCore.post(`/api/saveSolicitacaoStatusManual`, data)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          reject(err.response)
        })
    }),
    getAllStatusManual: () => new Promise((resolve, reject) => {
      apiCore.get('/api/requestStatusType/statusManual')
      .then(res => {
        resolve(res.data.content)
      })
      .catch(err => {
        console.error(err)
        reject(err.response)
      })
    })
  }
}

export default solicStatus
