import Vue from 'vue';
import VueRouter from 'vue-router';
import LayoutDefault from '@/layouts/default';
import LayoutDashboard from '@/layouts/dashboard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: LayoutDashboard,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/home')
      },
      {
        path: '/base-dados/usuarios',
        name: 'Usuarios',
        component: () => import('../views/database/usuarios/list')
      },
      {
        path: '/base-dados/leads',
        name: 'Leads',
        component: () => import('../views/database/leads/list')
      },
      {
        path: '/base-dados/solicitacoes',
        name: 'Solicitacoes',
        component: () => import('../views/database/solicitacoes/list')
      },
      {
        path: '/base-dados/quiz',
        name: 'Quiz',
        component: () => import('../views/database/quiz/list')
      },
      {
        path: '/base-dados/perguntas',
        name: 'Perguntas',
        component: () => import('../views/database/perguntas/list')
      }
    ]
  },
  {
    path: '/login',
    component: LayoutDefault,
    children: [{
      path: '',
      name: 'Login',
      component: () => import('../views/login')
    }]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
