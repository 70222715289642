import { apiCore } from '../api'

const requestStatus = {
  namespaced: true,
  state: () => ({
    data: [],
    status: 0,
    error: ''
  }),
  mutations: {
    setData: (state, payload) => state.data = payload,
    setStatus: (state, payload) => state.status = payload,
    setError: (state, payload) => state.error = payload
  },
  actions: {
    getAll: ({ commit }) => new Promise((resolve, reject) => {
      commit('setStatus', 1)
      apiCore.get(`/api/requestStatus`)
        .then(res => {
          commit('setStatus', 0)
          commit('setData', res.data.content)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
    getOne: ({ commit }, { id }) => new Promise((resolve, reject) => {
      commit('setStatus', 1)
      apiCore.get(`/api/requestStatus/${id}`)
        .then(res => {
          commit('setStatus', 0)
          commit('setData', [res.data.content])
          resolve([res.data.content])
        })
        .catch(err => {
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
    getAllByRequest: ({ commit }, { id }) => new Promise((resolve, reject) => {
      commit('setStatus', 1)
      apiCore.get(`/api/requestStatus/request/${id}`)
        .then(res => {
          commit('setStatus', 0)
          commit('setData', res.data.content)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
  }
}

export default requestStatus
