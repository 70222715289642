import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'font-awesome/css/font-awesome.min.css';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#2364a9',
        secondary: '#b0bec5',
        accent: '#ffcd35',
        error: '#b71c1c',
      },
    },
  },
  icons: {
    iconfont: 'mdi' || 'fa4', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
};

export default new Vuetify(opts);
