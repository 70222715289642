import { apiCore } from "@/store/api";

const auth = {
  namespaced: true,
  state: () => ({
    data: {},
    status: 0,
    error: ''
  }),
  getters: {
    isAuthenticated(state) {
      return state.data.token && true
    }
  },
  mutations: {
    setData: (state, payload) => state.data = payload,
    setStatus: (state, payload) => state.status = payload,
    setError: (state, payload) => state.error = payload
  },
  actions: {
    revokeAthentication: ({ commit }, { callback }) => {
      commit('setData', {})
      sessionStorage.removeItem('vuex')
      callback && callback()
    },
    authentication: ({ commit }, { email, password }) => new Promise((resolve, reject) => {
      commit('setStatus', 1)
      apiCore.post(`/api/auth/backoffice`, { email, password })
        .then(res => {
          commit('setStatus', 0)
          commit('setData', { token: res.data.content })
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    })
  }
}

export default auth
