import vuetify from './vuetify'
import vue2Filters from './vue2-filters'
import vueTheMask from './vue-the-mask'
import vueHotkey from './v-hotkey';

export default {
  vuetify,
  vue2Filters,
  vueTheMask,
  vueHotkey
}
