<template>
  <div class="layout-horizontal fill-height">
    <sidebar-default />
    <router-view />
  </div>
</template>

<script>
import SidebarDefault from "@/components/menus/sidebar-default";
export default {
  name: "horizontal",
  components: {SidebarDefault}
}
</script>

<style scoped>

</style>
