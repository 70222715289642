import axios from "axios";

export const baseUrlCore =
  process.env.NODE_ENV === "production"
    ? "https://core.selfcred.com.br"
    : process.env.NODE_ENV === "homolog"
      ? "https://core-dev.selfcred.com.br"
      : "http://localhost:5000"

export const baseUrlReports =
  process.env.NODE_ENV === "production"
    ? "https://reports.selfcred.com.br"
    : "http://localhost:6080"

const apiToken = {
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ._PolPB5lAzRq955YTkhDaDGHYcEUcMdVZzENnhJUJls'
}

const apiCore = axios.create({
  baseURL: baseUrlCore,
  headers: apiToken
})

const apiReports = axios.create({
  baseURL: baseUrlReports,
  headers: apiToken
})

// export default axios.create({ baseURL });
export {
  apiCore,
  apiReports,
  apiToken
};
