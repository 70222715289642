import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import plugins from './plugins';
import filters from './filters';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(VueFileAgent);

Vue.config.productionTip = false;

new Vue({
  ...plugins,
  router,
  store,
  filters,
  render: (h) => h(App),
}).$mount('#app');
