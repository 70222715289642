import { apiCore } from '../api'

const lead = {
  namespaced: true,
  state: () => ({
    data: {},
    status: 0,
    error: ''
  }),
  getters: {
    getData: state => {
      return Array.isArray(state.data.leads) ? state.data.leads.map(e => {
        e.idade = e.dataNasc ? new Date().getFullYear() - e.dataNasc.split("/").reverse()[0] : ''
        return e;
      }) : []
    }
  },
  mutations: {
    setData: (state, payload) => state.data = payload,
    setStatus: (state, payload) => state.status = payload,
    setError: (state, payload) => state.error = payload
  },
  actions: {
    getAll: ({ commit }, { page }) => new Promise((resolve, reject) => {
      commit('setStatus', 1)
      apiCore.get(`/api/lead?page=${page}`)
        .then(res => {
          commit('setStatus', 0)
          commit('setData', res.data.content)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
    getOne: ({ commit }, { id }) => new Promise((resolve, reject) => {
      commit('setStatus', 1)
      apiCore.get(`/api/lead/${id}`)
        .then(res => {
          commit('setStatus', 0)
          commit('setData', [res.data.content])
          resolve([res.data.content])
        })
        .catch(err => {
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    })
  }
}

export default lead
