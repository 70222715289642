import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import auth from './auth'
import database from './database'
import dashboard from './dashboard'
import {
  baseUrlCore,
  baseUrlReports
} from "@/store/api";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

export default new Vuex.Store({
  state: () => ({
    baseApiURL: baseUrlCore,
    baseReportsApiURL: baseUrlReports,
    emitEventHomeOnSearch: false,
    showModalExportSolics: false
  }),
  mutations: {
    setEmitEventHomeOnSearch: (state, value) => state.emitEventHomeOnSearch = value,
    setShowModalExportSolics: (state, payload) => state.showModalExportSolics = payload

  },
  modules: {
    auth,
    database,
    dashboard
  },
  plugins: [vuexLocal.plugin]
});
