<template>
  <div class="sidebar-default">
    <v-navigation-drawer
        v-model="drawer"
        app
        :mini-variant.sync="mini"
        :expand-on-hover="!fixed"
    >
      <v-list dense nav>
        <v-layout justify-end align-center class="mb-3">
          <v-list-item class="mr-3">
            <v-list-item-avatar tile>
              <v-img src="/selfcred-logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-action-text>
                Backoffice - Selfcred
              </v-list-item-action-text>
            </v-list-item-content>
          </v-list-item>
          <v-btn
              icon
              @click.stop="onClickToggleMenu"
          >
            <v-icon>{{fixed ? 'mdi-chevron-down' : (mini ? 'mdi-chevron-right' : 'mdi-chevron-left')}}</v-icon>
          </v-btn>
        </v-layout>

        <v-list-group
            v-for="item in menu"
            :key="item.title"
            prepend-icon=""
            :append-icon="item.submenu ? 'mdi-chevron-down' : ''"
            :ripple="false"
        >
          <template #activator>
            <v-list-item
                :to="item.to"
                v-bind="{
                  link: !!item.to
                }"
                v-on="{click: item.to ? onClickMenuItem : () => {}}"
                :ripple="false"
                id="subgroup"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-group
              v-for="subitem in item.submenu"
              :key="subitem.title"
              prepend-icon=""
              sub-group
              :ripple="false"
          >
            <template #activator>
              <v-list-item class="pl-8"
                           :to="subitem.to"
                           exact
                           link
                           @click="onClickSubMenuItem"
                           :ripple="false"
              >
                <v-list-item-icon>
                  <v-icon>{{ subitem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-list-item class=""
                       exact
                       link
                       @click="onClickLogout">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    fixed: false,
    menu: [
      {
        title: "Dashboard", icon: "mdi-view-dashboard", to: "/"
      },
      {
        title: "Base de Dados", icon: "mdi-database",
        submenu: [
          { title: "Usuarios", icon: "mdi-account", to: "/base-dados/usuarios" },
          { title: "Leads", icon: "mdi-account-multiple", to: "/base-dados/leads" },
          { title: "Solicitações", icon: "mdi-swap-horizontal-bold", to: "/base-dados/solicitacoes" },
          { title: "Quiz", icon: "mdi-message-bulleted", to: "/base-dados/quiz" },
          { title: "Perguntas", icon: "mdi-message", to: "/base-dados/perguntas" }
        ]
      },
    ],
  }),
  computed: {
    mini: {
      set (value) {
        return value
      },
      get() {
        return !this.fixed
      },
    }
  },
  methods: {
    onClickMenuItem() {
      if(!this.fixed) {
        this.collapseMenu()
      }
    },
    onClickSubMenuItem() {
      if(!this.fixed) {
        this.collapseMenu()
      }
    },
    onClickToggleMenu() {
      this.fixed = !this.fixed;
      if(!this.fixed) {
        this.collapseMenu()
      } else {
        this.expandMenu()
      }
    },
    onClickLogout() {
      this.$store.dispatch('auth/revokeAthentication', {
        callback: () => {
          this.$router.push({name: 'Login'})
        }
      })
    },
    expandMenu() {
      document.querySelector('.v-main').setAttribute('style', 'padding: 0px 0px 0px 256px')
    },
    collapseMenu() {
      document.querySelector('.v-main').setAttribute('style', 'padding: 0px 0px 0px 56px')
      document.querySelector('.v-navigation-drawer').style.width = '56px'
    }
  }
}
</script>

<style lang="scss">
.v-list-group__header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-list-group.v-list-group--sub-group {
  .v-list-group__header {
    &:hover {
      background: transparent !important;
    }
    &.theme--light.v-list-item:hover:before {
      opacity: 0 !important;
    }
  }
}

.v-list-group {
  .theme--light.v-list-item:focus:before {
    opacity: 0!important;
  }

  #subgroup.theme--light.v-list-item:hover:before {
    opacity: 0!important;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: inherit !important;
  }

  &.v-list-group--active {
    .v-list-item__icon {
      .theme--light.v-icon {
        &.theme--light {
          color: inherit !important;
        }
      }
    }
  }
}
</style>
