<template>
  <div class="layout-dashboard">
    <v-app>
      <v-main>
        <horizontal v-if="$vuetify.breakpoint.mdAndUp" />
        <vertical v-if="$vuetify.breakpoint.smAndDown" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Horizontal from "./horizontal";
import Vertical from "./vertical";
export default {
  name: "dashboard",
  components: {Vertical, Horizontal},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(vm.$store.getters["auth/isAuthenticated"]) {
        return next()
      } else {
        vm.$router.push({name: 'Login'})
      }
    })
  }
}
</script>
