import user from './user'
import lead from './lead'
import request from './request'
import requestStatus from './request-status'
import answerQuiz from './answer-quiz'
import quiz from './quiz'
import question from './question'
import solicStatus from './solic-status'

const database = {
  namespaced: true,
  modules: {
    user,
    lead,
    request,
    requestStatus,
    answerQuiz,
    quiz,
    question,
    solicStatus
  }
}

export default database
